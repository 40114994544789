import { IRandomPhrase } from "./interfaces/IRandomPhrase";

export const RandomPhrase: IRandomPhrase[] = [
    { phrase: "La única razón por la que preguntamos a otras personas cómo fue su fin de semana es para que podamos contarles sobre nuestro propio fin de semana.", author: "Chuck Palahniuk"},
    { phrase: "Los fines de semana no cuentan a menos que los gastes haciendo algo completamente inútil.", author: "Bill Watterson"},
    { phrase: "Trata siempre de sobresalir pero solo los fines de semana.", author: "Richard Rorty"},
    { phrase: "Manos arriba si estás listo para hacer algo que lamentarás este fin de semana. ¡Ve a por ello! Tienes mi bendición.", author: "Florence Welch"},
    { phrase: "Siempre estoy feliz de conocer a mi amigo, y mi amigo es mi fin de semana.", author: "Debasish Mridha"},
    { phrase: "El domingo elimina el óxido de toda la semana.", author: "Joseph Addison"},
    { phrase: "Los fines de semana son un poco como el arco iris, se ven bien de lejos pero desaparecen cuando te acercas a ellos.", author: "John Shirley"},
    { phrase: "Los mejores días están llegando. Se llaman sábados y domingos.", author: "Desconocido"},
    { phrase: "No hay suficientes días los fines de semana.", author: "Rod Schmidt"},
    { phrase: "Hay una línea fina entre el sábado por la noche y el domingo por la mañana.", author: "Jimmy Buffett"},
    { phrase: "Ten la costumbre de levantarte temprano los fines de semana. ¿Por qué perder tan precioso tiempo en la cama?", author: "Marilyn Vos Savant"},
    { phrase: "Un fin de semana salvaje y loco implica sentarse en el porche delantero, fumar un cigarro y leer un libro.", author: "Robert M. Gates"},
    { phrase: "Los domingos por la noche a menudo se sienten como si el fin de semana se acaba antes de que comience.", author: "Catherine McCormack"},
    { phrase: "¿Por qué esperar el fin de semana para divertirse?", author: "Loesje"},
    { phrase: "Un domingo bien gastado trae una semana llena de nuevo contenido.", author: "Proverbio"},
    { phrase: "Estoy aprendiendo el poder de irme durante el fin de semana y hacerme compañía.", author: "Zoe Saldana"},
    { phrase: "¿Sabes lo que quiero hacer? Despertar un fin de semana y no tener que ir a ningún lado y no hacer nada.", author: "Derek Jeter"},
    { phrase: "La música siempre suena mejor el viernes.", author: "Lou Brutus"},
    { phrase: "¿Por qué el lunes está tan lejos del viernes y el viernes tan cerca del lunes?", author: "Anónimo"},
    { phrase: "Dedique algo de tiempo este fin de semana en mejoras para el hogar; mejora tu actitud hacia tu familia.", author: "Bo Bennett"},
    { phrase: "La vida puede ser un sábado gris y miserable para muchos, pero se debe tratar de hacer de esos días los mejores vividos.", author: "Anthony Burgess"},
    { phrase: "Creo que es una Ley obligatoria del Universo que los viernes tienes que hacer algo un poco divertido.", author: "Hanna Rhoades"},
    { phrase: "Los fines de semana no pagan tan bien como los días de semana pero al menos hay fútbol. S.A.", author: "Sachs"},
    { phrase: "La sensación del domingo es la misma en todas partes, pesada, melancólica, inmóvil.", author: "Jean Rhys"},
    { phrase: "En el Sexto Día, Dios creó al hombre, el tipo de resultado que a menudo obtienes cuando vas a trabajar un sábado.", author: "Robert Brault"},
    { phrase: "Estaría muy feliz si pasara desde el sábado por la noche hasta el lunes por la mañana sola en mi apartamento.", author: "Audrey Hepburn"},
    { phrase: "Todavía trabajo los fines de semana porque me gusta; Lo disfruto. Simplemente no me importa.", author: "Edgardo Osorio"},
    { phrase: "Los viernes son los más difíciles en algunos aspectos: estás muy cerca de la libertad.", author: "Lauren Oliver"},
    { phrase: "Los fines de semana son sagrados para mí. Son el momento perfecto para relajarse y pasar tiempo con familiares y amigos.", author: "Marcus Samuelsson"},
    { phrase: "A menos que sea una emergencia, no me molestes después de las 6:00 p.m. y los fines de semana.", author: "Merv Griffin"},
    { phrase: "¡El fin de semana es para poner en forma! En forma horizontal y seguir durmiendo hasta tarde.", author: "Anónimo"},
    { phrase: "No importa que tan larga haya sido tu semana ni qué tan cansado estés. La única cosa que no puede reciclarse es el tiempo desechado ¡Aprovecha al máximo este fin de semana!", author: "Anónimo"},
    { phrase: "Siempre pon parte de la diversión, la libertad y la maravilla del fin de semana en tu semana de trabajo.", author: "Rasheed Ogunlaru"},
    { phrase: "¡Los fines de semana son demasiado cortos para dormir!", author: "Bryant A. Loney"},
    { phrase: "No es que pasemos cinco días de la semana esperando sólo dos. Es que la mayoría de la gente hace lo que más le gusta en esos dos días.", author: "James A. Murphy"},
    { phrase: "Los fines de semana señalan el inicio de un nuevo comienzo.", author: "Anónimo"},
    { phrase: "La música es el silencio que hay entre las notas, mis silencios favoritos se llaman: sábado y domingo.", author: "Anónimo"},
    { phrase: "No importa cuánto estrés hayas tenido estos cinco días, no hay nada que un buen fin de semana no pueda curar.", author: "Anónimo"},
    { phrase: "Si necesitas una motivación fuerte piensa en las locuras que harás este fin de semana.", author: "Anónimo"},
    { phrase: "El único final que todos esperamos con ansias es el fin de la semana.", author: "Anónimo"},
    { phrase: "Trato que cada noche y fin de semana sean como pequeñas rebanadas de jubilación porque a nadie se le garantiza una larga al final de su carrera.", author: "Mike Hammar"},
    { phrase: "Un fin de semana no tiene sentido si lo pasas haciendo algo que tenga sentido.", author: "Anónimo"},
    { phrase: "¡El fin de semana es un momento para no usar el despertador, para estar sin prisas, sin llamadas telefónicas urgentes y sin noches de trabajo!", author: "Catherine Pulsifer"},
    { phrase: "¿Has escuchado el dicho vendrán días mejores? Pues es cierto, se llaman sábado y domingo.", author: "Anónimo"},
    { phrase: "Necesitamos que el espíritu del domingo se prolongue hasta el lunes y continúe hasta el sábado.", author: "Edward McKendree"},
    { phrase: "Somos afortunados de tener dos días a la semana en los cuales no nos vemos, así podemos darnos tiempo suficiente de extrañarnos ¡Feliz fin de semana!", author: "Anónimo"},
    { phrase: "Imagina vivir una vida donde todos los días son sábados y domingos. Haz que todos los días sean tú fin de semana. Haz todos los días un día de juego.", author: "James A. Murphy"},
    { phrase: "El fin de semana existe para que lo pases de lo mejor haciendo lo que mejor te parezca. No lo desperdicies ¡Feliz fin de semana!", author: "Anónimo"},
    { phrase: "Trabajo sobre todo durante la semana para pasar el fin de semana un rato con amigos, así se equilibra bastante bien la vida.", author: "Jimmy Bennett"},
    { phrase: "Un estilo de vida saludable no se define sólo por lo que comes. También lo que vives emocional, mental y espiritualmente ¡Asegúrate de tener un fin de semana con una dieta saludable!", author: "Anónimo"},
    { phrase: "Sé que has trabajado duro toda la semana, así que te conseguí un pequeño regalo. Se llama sábado y domingo. Espero que lo disfrutes.", author: "Anthony T. Hincks"},
    { phrase: "Siempre doy el 100% en el trabajo: 13% Lunes, 22% Martes, 26% Miércoles, 35% Jueves, 4% Viernes. Así tengo suficientes energías para cuando llega el fin de semana.", author: "Anónimo"},
    { phrase: "Cada día es un regalo. Pero los fines de semana se llevan mejor.", author: "Sanhita Baruah"},
    { phrase: "Las palabras de un hombre ebrio en un fin de semana no son más que sus pensamientos contenidos durante los últimos cinco días.", author: "Anónimo"},
    { phrase: "Tengo tantos planes para el fin de semana que no sé ni por dónde empezar.", author: "Anónimo"},
    { phrase: "Disfrutar con la familia y los seres queridos es el único deber que tenemos en nuestro fin de semana.", author: "Anónimo"},
    { phrase: "¡Cada minuto cuenta si se trata de disfrutar al maximo el fin de semana!", author: "Anónimo"},
    { phrase: "Deshazte del hábito de pararte temprano ya que no hay razón para desperdiciar ese tiempo de calidad con tu almohada el fin de semana.", author: "Anónimo"},
    { phrase: "Con el fin de semana llegan mis ganas de amigos y diversión, pero también de películas, sofá y manta.", author: "Anónimo"},
    { phrase: "La parte más difícil después de un fin de semana de fiesta son sólo los primeros cinco días, des pues de eso ya podemos retomarlo.", author: "Anónimo"},
    { phrase: "Durante los fines de semana no pienses en los lunes, llegan pronto.", author: "Robert Rivers"},
    { phrase: "Dale a un hombre un pescado y tiene comida por un día; enseñarle a pescar y puedes deshacerte de él durante todo el fin de semana.", author: "Zenna Schaffer"},
    { phrase: "¡Cada minuto cuenta si se trata de disfrutar de todo el fin de semana!", author: "Anónimo"},
    { phrase: "Es fin de semana, lo que significa que me siento cien por ciento motivado para no hacer absolutamente nada.", author: "Anónimo"},
    { phrase: "No sé si a ti también te pasa, pero a mí, ¡las mejores aventuras me acontecen los fines de semana!", author: "Anónimo"},
    { phrase: "Hay pocas posibilidades de que los meteorólogos puedan resolver los misterios del clima hasta que entiendan la atracción mutua entre la lluvia y los fines de semana.", author: "Arnot Sheppard"},
    { phrase: "No hay mejores recuerdos que aquellos que pasas los fines de semana con buenos amigos.", author: "Anónimo"},
    { phrase: "El mayor placer en la vida es hacer lo que la gente te dice que no eres capaz de hacer los fines de semana.", author: "Walter Bagehot"},
    { phrase: "Cuando el viernes acaba, la fiesta no para. ¡Que venga el fin de semana!", author: "Anónimo"},
    { phrase: "Los hombres son cosas sencillas. Pueden sobrevivir todo un fin de semana con sólo tres cosas: bebidas, pantalones cortos y baterías para el mando a distancia.", author: "Diana Jordan"},
    { phrase: "Usa tus fines de semana en ayudar a otros a ser más felices. ¡Jamás será demasiado tarde para comenzar!", author: "Anónimo"},
    { phrase: "No existe nada mejor como pasar el fin de semana entero en pijama.", author: "Anónimo"},
    { phrase: "El fin de semana es un tipo de medicina que sana a aquellos que se encuentran enfermos de la rutina del resto de la semana.", author: "Anónimo"},
    { phrase: "A veces el fin de semana es secuestrado por el trabajo, pero como diría mi madre, eso no es mi problema.", author: "Julian Fellowes"},
    { phrase: "Hay personas que dicen que el fin de semana está para no parar, pero yo prefiero aprovecharlo para descansar.", author: "Anónimo"},
]