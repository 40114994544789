import { EsLowerCase } from "./weekdays";
import { DetailPhrase } from "./texts";
import { RandomPhrase } from "./random-phrase";
import { IRandomPhrase } from "./interfaces/IRandomPhrase";
import { RandomPhoto } from "./random-photo";
import { IRandomPhoto } from "./interfaces/IRandomPhoto";

export class Main {

        private currentDate: Date = new Date();

        constructor() {

                this.UpdateDate();
                this.UpdateDetailPhrase();
                this.UpdateRandomPhrase();
                this.UpdateRandomPhoto();

        }

        public UpdateDetailPhrase() {

                let element = document.getElementById("detail-phrase");

                if (element) {

                        element.innerHTML = DetailPhrase;

                }

        }

        public UpdateRandomPhrase() {

                const index = Math.trunc(Math.random() * RandomPhrase.length);
                const phrase: IRandomPhrase = RandomPhrase[index];

                const phraseElement = document.getElementById("random-phrase");
                const phraseElementAuthor = document.getElementById("random-phrase-author");

                if (phraseElement && phraseElementAuthor) {
                        phraseElement.innerText = phrase.phrase;
                        phraseElementAuthor.innerText = phrase.author;
                }

        }

        public UpdateRandomPhoto() {

                const index = Math.trunc(Math.random() * RandomPhoto.length);
                const photo: IRandomPhoto = RandomPhoto[index];

                const photoAuthor = document.getElementById("credits-author");
                const photoLink = document.getElementById("credits-link")
                
                document.body.style.background = `url('${photo.url}') no-repeat center center fixed`;

                if (photoAuthor) {
                        photoAuthor.innerText = photo.author;
                }

                if (photoLink) {
                        photoLink.setAttribute("href", photo.referralUrl);
                }

        }

        public UpdateDate() {

                this.currentDate = new Date();

                this.UpdateClock();
                this.UpdateCurrentDay();
                this.UpdateDayDescription();           
        
        }    
        
        private UpdateClock(){

                let element = document.getElementById("clock");

                if (element) {
                        element.innerHTML = this.currentDate.toLocaleString();
                }                

        }

        private UpdateCurrentDay(){

                let element = document.getElementById("current-day");
                
                let dayName = EsLowerCase[this.currentDate.getDay()];

                if (element) {
                        element.innerHTML = `Hoy es ${dayName}`;
                }
                

        }

        private UpdateDayDescription() {

                let element = document.getElementById("day-description");
                
                let dayDescription = "";
                switch(this.currentDate.getDay()) {
                        case 0: dayDescription = "Aún es fin de semana, pero te queda poco... ¡disfrútalo!"
                                break;
                        case 1: dayDescription = "¡Ánimo! Seguro que te espera una bonita semana por delante"
                                break;
                        case 2: dayDescription = "Piensa que queda un día menos para el ansiado fin de semana"
                                break;
                        case 3: dayDescription = "Estás justo en el ecuador de la semana, ¡podía ser peor!"
                                break;
                        case 4: dayDescription = "Aguanta sólo un poco más..."
                                break;
                        case 5: dayDescription = "En unas horas disfrutarás de tu apreciado descanso"
                                break;
                        case 6: dayDescription = "Es fin de semana, ¡sal a decírselo a los demás!"
                                break;
                }

                if (element) {
                        element.innerHTML = dayDescription;
                }

        }

}
